const customsClearanceLocationData = [
    {
        label: "Porto",
        value: "PORTO",
    },
    {
        label: "Aeroporto",
        value: "AEROPORTO",
    },
    {
        label: "Fronteira",
        value: "FRONTEIRA",
    },
    {
        label: "Zona Secundária",
        value: "ZONA_SECUNDARIA",
    },
    {
        label: "Desova",
        value: "DESOVA",
    },
];

export default customsClearanceLocationData;